import React, { useEffect } from "react"
import { useState } from "react"
import "./dashboard-settings.css"
import DashboardNavbar from "../../../components/Dashboard Navbar/DashboardNavbar"
import SettingsPersonalInfo from "./SettingsPersonalInfo"
import SettingsPassword from "./SettingsPassword"
import SettingsBankInfo from "./SettingsBankInfo"
import SettingsPaymentOptions from "./SettingsPaymentOptions"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"
import SettingsUserVerification from "./SettingsUserVerification"
import Miscellaneous from "./Miscellaneous"
import TeamRoles from "./TeamRoles"

const DashboardSettingsContainer = () => {
  const [toggleState, setToggleState] = useState(1)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  const toggleTab = (index) => {
    setToggleState(index)
  }

  useEffect(() => {
    setLoading(false)
    let id = localStorage.getItem("id")

    axios
      .post("/get-user", {
        id: id,
      })
      .then((data) => {
        setUser(data.data.user)
      })
      .catch((err) => {
        console.log(err)
      })

    axios.post('/list-members', {
      team_id: localStorage.getItem("id"),
      id: localStorage.getItem("id"),
    })
      .then(res => {
        setRoles(res.data.team_members)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

  }, [])

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className="dashboard-overview-container">
      <DashboardNavbar />

      <main className="settings-container">
        <header>
          <h3>Account Settings</h3>

          <span>
            {/* <Link to="/dashboard/create-transaction-link">
              <button className="discard-btn">Discard Changes</button>
            </Link> */}

            {/* <button className="save-changes-btn" onClick={() => {}}>Save Changes</button> */}
          </span>
        </header>

        <div className="settings-tabs">
          {['Owner'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 1 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(1)}
          >
            <p>Personal Info</p>
          </span>}

          {['Owner'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 2 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(2)}
          >
            <p>Bank Info</p>
          </span>}

          {['Owner'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 3 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(3)}
          >
            <p>Password</p>
          </span>}

          {['Owner', 'Developer'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 4 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(4)}
          >
            <p>API Keys</p>
          </span>}

          {['Owner'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 5 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(5)}
          >
            <p>Other Setting</p>
          </span>}

          {['Owner'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 6 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(6)}
          >
            <p>Payment Options</p>
          </span>}

          {['Owner'].includes(localStorage.getItem('role')) && <span
            className={
              toggleState === 7 ? "settings-tab active-tab" : "settings-tab"
            }
            onClick={() => toggleTab(7)}
          >
            <p>Team Roles</p>
          </span>}

          {/* <span
            className={
              toggleState === 5 ? 'settings-tab active-tab' : 'settings-tab'
            }
            onClick={() => toggleTab(5)}
          >
            <p>Terms & Policies</p>
          </span> */}
        </div>

        {toggleState === 1 && (
          <SettingsPersonalInfo
            user={user}
            className={
              toggleState === 1
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}

        {toggleState === 2 && (
          <SettingsBankInfo
            user={user}
            banking={user.bank}
            className={
              toggleState === 4
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}

        {toggleState === 3 && (
          <SettingsPassword
            user={user}
            className={
              toggleState === 3
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}

        {toggleState === 4 && (
          <SettingsUserVerification
            user={user}
            className={
              toggleState === 2
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}

        {/* {toggleState === 5 && (
          <SettingsPassword user={user}
            className={
              toggleState === 5
                ? 'settings-content  active-settings-content'
                : 'settings-content'
            }
          />
        )} */}

        {toggleState === 5 && (
          <Miscellaneous
            user={user}
            className={
              toggleState === 5
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}

        {toggleState === 6 && (
          <SettingsPaymentOptions
            user={user}
            className={
              toggleState === 6
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}

        {toggleState === 7 && (
          <TeamRoles
            user={user}
            roles={roles}
            className={
              toggleState === 7
                ? "settings-content  active-settings-content"
                : "settings-content"
            }
          />
        )}
      </main>
    </section>
  )
}

export default DashboardSettingsContainer
