import React, { useEffect, useState } from "react"
import "./dashboard-overview.css"
import DashboardNavbar from "../../../components/Dashboard Navbar/DashboardNavbar"
import OverviewBalance from "./OverviewBalance"
import DashboardRecentTransaction from "./DashboardRecentTransaction"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"
import { DropDownFilter } from "../../../components/filter"

const timeInterval = ["Day", "Week", "Month", "Year"];
const DasbhoardContainer = () => {
  const [user, setUser] = useState({})
  const [bank, setBank] = useState({})
  const [transactions, setTransaction] = useState([])
  const [links, setLinks] = useState([])
  const [loading, setLoading] = useState(false)
  const [pending, setPending] = useState("")
  const [interval, setInterval] = useState([new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(24, 0, 0, 0))]);

  useEffect(() => {
    setLoading(true)
    let id = localStorage.getItem("id")

    axios
      .post("/get-user", {
        id: id,
      })
      .then((data) => {
        setUser(data.data.user)
        setPending(data.data.pending)

        localStorage.setItem("balance_", parseInt(data.data.user.balance) - parseInt(data.data.pending))
      })
      .catch((err) => {
        console.log(err)
      })

    axios
      .post("/get-wallet", {
        user_id: id,
        id: localStorage.getItem("id"),
      })
      .then((data) => {
        setBank(data.data.wallet)
      })
      .catch((err) => {
        console.log(err)
      })

    axios
      .post("/get-user-transactions", {
        user_id: id,
        id: localStorage.getItem("id"),
        filter: interval
      })
      .then((data) => {
        setTransaction(data.data.transactions)
      })
      .catch((err) => {
        console.log(err)
      })

    axios
      .post("/payments/get-user-links", {
        id,
      })
      .then((data) => {
        setLoading(false)
        setLinks(data.data.link_data)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }, [interval])

  if (loading) {
    return (
      <div>
        <div
          className="flex md:hidden"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "60%",
          }}
        >
          <ClipLoader
            color={"#060D1C"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>

        {/*Desktop */}
        <div
          className="hidden md:flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
        >
          <ClipLoader
            color={"#060D1C"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    )
  }

  return (
    <section className="dashboard-overview-container">
      <DashboardNavbar name={user.first_name} />
      <DropDownFilter timeInterval={timeInterval} interval={interval} setInterval={setInterval} />
      <OverviewBalance user={user} links={links} bank={bank} pending={pending} />
      <DashboardRecentTransaction transactions={transactions} user={user} />
    </section>
  )
}

export default DasbhoardContainer
