import React, { useRef } from "react"
// import Navbar from "../../components/Navbar/Navbar"
import "./pos.css"
// import LoggedInNavbar from "../../components/Navbar/LoggedInNavbar"
import Barcode from "react-barcode"
import { useReactToPrint } from 'react-to-print';

export default function POS({ trx, closePrint, user }) {

  // const [thn, setThn] = useState("")
  const receiptRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });
  // const details = [
  //   {
  //     title: "Name of account",
  //     value: "John Doe",
  //   },
  //   {
  //     title: "Transaction Date",
  //     value: "24 June 202, 20:00",
  //   },
  //   {
  //     title: "Transaction Name",
  //     value: "R122YGYGGT6T6T6T6",
  //   },
  //   {
  //     title: "Transaction Amount",
  //     value: "N 20120",
  //   },
  //   {
  //     title: "Transaction Status",
  //     value: "Done",
  //   },
  // ]

  const created_date = new Date(trx.createdAt)
  // let hours = created_date.getHours();
  // let period = 'am'
  // if (hours > 12) {
  //   hours = hours - 12;
  //   period = 'pm';
  // }
  // let minutes = created_date.getMinutes()

  return (
    <main>
      {/* <LoggedInNavbar /> */}

      <div className="flex flex-col items-center mt-2">
        <div className="flex justify-between w-72 md:w-96">
          <p className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full cursor-default" onClick={() => closePrint()}>Close</p>

          <p className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-default" onClick={() => handlePrint()}>Print</p>
        </div>

        <section className="flex flex-col mt-6 md:w-96 md:p-10 items-center border-2" ref={receiptRef}>
          <div className="flex flex-col">
            <h2 className="text-xl text-primary outline-2 outline-primary ml-5 md:mt-0 mt-2">
              Kudipal Receipt
            </h2>
            <object className="grid">
              {/* {details.map((detail) => (
                <span className="flex flex-col p-5">
                  <p className="">
                    {detail.title}
                  </p>
                  <p className="">
                    {detail.value}
                  </p>
                </span>
              ))} */}

              <span className="flex flex-col p-5">
                <p className="">
                  Business name
                </p>
                <p className="">
                  {user.business.business_name}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Transaction Date
                </p>
                <p className="">
                  {created_date.getDate()}/{created_date.getMonth() + 1}/{created_date.getFullYear()}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Transaction Type
                </p>
                <p className="">
                  {trx.type}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Transaction name
                </p>
                <p className="">
                  {trx.name}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Amount
                </p>
                <p className="">
                  &#8358;{Number(trx.amount).toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Settled Amount
                </p>
                <p className="">
                  &#8358;{Number(trx.settlement_amount).toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Fee
                </p>
                <p className="">
                  &#8358;{Number(parseInt(trx.amount) - parseInt(trx.settlement_amount)).toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </p>
              </span>

              <span className="flex flex-col p-5">
                <p className="">
                  Status
                </p>
                <p className="">
                  {trx.status.toUpperCase()}
                </p>
              </span>

            </object>
          </div>
          <Barcode value={trx._id} displayValue />

          <p className="text-center text-3xl text-secondary"> Thank you for transacting with us</p>
        </section>

        <p className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-default" onClick={() => handlePrint()}>Print</p>
      </div>
    </main>
  )
}
