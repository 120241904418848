import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// import './general-settings.css';

import ChooseAccountDetails from './ChooseAccountDetails';
import WithdrawalAmount from './WithdrawalAmount';
// import WithdrawalAmount from './WithdrawalAmount';
import WithdrawalConfirmation from './WithdrawalConfirmation';
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"

const BalanceWithdrawalContainer = () => {
  const [withdrawalStep, setWithdrawalStep] = useState(1);
  const [banks, setBanks] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    axios.get('/get-active-bank-list')
      .then(res => {
        if (res.data.message === "success") {
          setBanks(res.data.banks)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })

  },[])

  const nextWithdrawalStep = () => {
    setWithdrawalStep(withdrawalStep + 1);
  };

  const previousWithdrawalStep = () => {
    setWithdrawalStep(withdrawalStep - 1);
  };

  switch (withdrawalStep) {
    case 1:
      return <ChooseAccountDetails nextWithdrawalStep={nextWithdrawalStep} banks={banks} />;
    case 2:
      return (
        <WithdrawalAmount
          nextWithdrawalStep={nextWithdrawalStep}
          previousWithdrawalStep={previousWithdrawalStep}
        />
      );
    case 3:
      return (
        <WithdrawalConfirmation
          nextWithdrawalStep={nextWithdrawalStep}
          previousWithdrawalStep={previousWithdrawalStep}
        />
      );
    default:
      console.log('This is a multi-step form built with React.');
  }

  if (loading) {
    return (
      <div>
        <div
          className="flex md:hidden"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "60%",
          }}
        >
          <ClipLoader
            color={"#060D1C"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>

        {/*Desktop */}
        <div
          className="hidden md:flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
        >
          <ClipLoader
            color={"#060D1C"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    )
  }

  return (
    <section className="dashboard-container">
      {/* <SideBar /> */}

      <main className="dashboard-main">
        {/* <TopBar /> */}

        <main className="add-new-property-container withdrawal-container">
          <header>
            <Link to="/apartments" className="apa">
              <iconify-icon
                class="back-iconify"
                icon="bx:arrow-back"
              ></iconify-icon>
              <p>Overview | Payment | Withdrawal </p>
            </Link>
            <h4>Balance Withdrawal</h4>
          </header>
        </main>
      </main>
    </section>
  );
};

export default BalanceWithdrawalContainer;
