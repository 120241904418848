import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUpContainer from './Pages/Sign-up/SignUpContainer';
import SignInContainer from './Pages/Sign-In/SignInContainer';
import DasbhoardContainer from './Pages/Dashboard/Dashboard Overview/DasbhoardContainer';
import DashboardTransactionContainer from './Pages/Dashboard/Dashboard Transaction/DashboardTransactionContainer';
import CreateTransactionLink from './Pages/Dashboard/Create Transaction Link/CreateTransactionLink';
import TransactionListContainer from './Pages/Dashboard/Dashboard List/ListContainer';
import DashboardSettingsContainer from './Pages/Dashboard/Dashboard Settings/DashboardSettingsContainer';
import BalanceWithdrawalContainer from './Pages/Dashboard/Dashboard Withdrawal/BalanceWithdrawalContainer';
import LandingPage from './Pages/Landing Page/LandingPage';
import Privacy from './Pages/Landing Page/PrivacyPolicy';
import Terms from './Pages/Landing Page/terms';
import ForgotPasswordContainer from './Pages/Forgot Password/ForgotPasswordContainer';
import SetNewPasswordContainer from './Pages/Set New Password/SetNewPasswordContainer';
import { RoleSelect } from './Pages/Sign-In/RoleSelect';
import POS from './Pages/Transactions/POS';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<LandingPage />} ></Route>
          <Route exact path='/privacy' element={<Privacy />} ></Route>
          <Route exact path='/terms' element={<Terms />} ></Route>
          <Route path="/sign-up" element={<SignUpContainer />}></Route>
          <Route path="/sign-in" element={<SignInContainer />}></Route>
          <Route path="/role-select" element={<RoleSelect />}/>
          <Route path="/dashboard" element={<DasbhoardContainer />}></Route>

          <Route path="/dashboard/transactions" element={<DashboardTransactionContainer />}></Route>

          <Route path="/dashboard/create-transaction-link" element={<CreateTransactionLink />}></Route>

          <Route path="/dashboard/transaction-links" element={<TransactionListContainer />}></Route>

          <Route path="/dashboard/settings" element={<DashboardSettingsContainer />}></Route>

          <Route path='/dashboard/withdrawal' element={<BalanceWithdrawalContainer />} ></Route>

          <Route
            path="/forgot-password"
            element={<ForgotPasswordContainer />}
          ></Route>

          <Route
            path="/reset-password/:id"
            element={<SetNewPasswordContainer />}
          ></Route>

          <Route path='/pos' element={<POS/>}/>

        </Routes>

      </Router>
    </>
  );
}

export default App;
