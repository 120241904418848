import React, { useState } from "react"
import "./dashboard-settings.css"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"

const Miscellaneous = ({ user }) => {
  const [notifyMe, setNotifyMe] = useState(user.notify_me)
  const [notifyCustomer, setNotifyCustomer] = useState(user.notify_customer)
  const [loading, setLoading] = useState(false)

  const update_personal = (ev) => {
    setLoading(true)

    ev.preventDefault()

    let data = {
      notify_customer: notifyCustomer,
      notify_me: notifyMe,
      id: localStorage.getItem("id"),
      user_id: localStorage.getItem("id"),
    }

    axios
      .post("/update-user", data)
      .then((res) => {
        if (res.data.message === "success") {
          setLoading(false)
          alert("Success")
        } else {
          setLoading(false)
          alert(res.data.details)
        }
      })
      .catch((err) => {
        setLoading(false)
        alert(err)
        return false
      })
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className="settings">
      <header>
        <h4>Other Settings</h4>
        <p>Make other settings to your account</p>
      </header>

      <form action="" className="formy">
        <div className="options">
          <span className="flex flex-row mt-5">
            <input
              type="checkbox"
              name="options"
              value="card"
              onChange={(ev) => setNotifyMe(!notifyMe)}
              checked={notifyMe}
            />
            <p className="absolute ml-12 mt-3.5">
              Send me notifications when a transaction happens on my account
            </p>
          </span>
          <span className="flex flex-row mt-5">
            <input
              type="checkbox"
              name="options"
              value="ussd"
              onChange={(ev) => setNotifyCustomer(!notifyCustomer)}
              checked={notifyCustomer}
            />
            <p className="absolute ml-12 mt-3.5">Send an email receipt to my customers</p>
          </span>
        </div>

        <button
          className="save-changes-btn save-btn"
          onClick={(ev) => update_personal(ev)}
        >
          Save Changes
        </button>
      </form>
    </main>
  )
}

export default Miscellaneous
