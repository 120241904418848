import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./list.css"
import toast, { Toaster } from "react-hot-toast"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"
import {
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TelegramIcon,
} from "react-share"

const List = () => {
  const [links, setLinks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios
      .post("/payments/get-user-links", { id: localStorage.getItem("id") })
      .then((res) => {
        setLinks(res.data.link_data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const link_disable = (id) => {
    setLoading(true)
    let data = {
      link_id: id,
      disabled: true,
      id: localStorage.getItem("id"),
      user_id: localStorage.getItem("id"),
    }

    axios
      .post("/payments/edit-link", data)
      .then((res) => {
        setLinks(links.filter((li) => li._id !== id))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        alert("An error occured!")
        console(err)
      })
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className="list-container">
      <header>
        <h3>Transaction List</h3>
        <Link to="/dashboard/create-transaction-link">
          <button>Create New Link</button>
        </Link>
      </header>

      <table className="recent-transactions">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Amount</th>
            {/* <th>Status</th> */}
            <th>Time</th>
            <th>Date</th>
            <th>Share</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {links.map((li) => (
            <tr>
              <td>{li._id}</td>
              <td>{li.name}</td>
              {li.mode === "Predefined" && (
                <td>
                  &#8358;
                  {Number(li.amount)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </td>
              )}
              {li.mode === "Custom" && <td>Custom</td>}
              {/* <td className="status">
              <span>Completed</span>
            </td> */}
              <td>{new Date(li.createdAt).getTime()}</td>
              <td>{new Date(li.createdAt).getDate()}</td>
              {/* <td onClick={() => {
                navigator.clipboard.writeText(`${li.link_format}`)

                toast("Share link copied", {
                  position: 'bottom-center',
                  icon: '👏',
                  style: {
                    border: '1px solid #713200',
                    padding: '16px',
                    height: '60px',
                    fontSize: "20px",
                    color: '#713200',
                  },
                });
              }}>copy link</td> */}
              <td>
                <EmailShareButton
                  subject={`${li.name} - Kudipal`}
                  body={`Use this link to make payments to my Kudipal account \n link: ${
                    li.link_format
                  }${li.reference ? `?reference=${li.reference}` : ""}`}
                  url={`${li.link_format}${
                    li.reference ? `?reference=${li.reference}` : ""
                  }`}
                >
                  <EmailIcon round={true} size={30} />
                </EmailShareButton>

                <WhatsappShareButton
                  title={`${li.name} - Kudipal`}
                  url={`Use this link to make payments to my Kudipal account \n link: ${
                    li.link_format
                  }${li.reference ? `?reference=${li.reference}` : ""}`}
                >
                  <WhatsappIcon round={true} size={30} />
                </WhatsappShareButton>

                {/* <InstapaperShareButton>
                  <InstapaperIcon />
                </InstapaperShareButton> */}

                <TwitterShareButton
                  title={`${li.name} - Kudipal`}
                  url={`Use this link to make payments to my Kudipal account \n link: ${
                    li.link_format
                  }${li.reference ? `?reference=${li.reference}` : ""}`}
                >
                  <TwitterIcon round={true} size={30} />
                </TwitterShareButton>

                <TelegramShareButton
                  title={`${li.name} - Kudipal`}
                  url={`Use this link to make payments to my Kudipal account \n link: ${
                    li.link_format
                  }${li.reference ? `?reference=${li.reference}` : ""}`}
                >
                  <TelegramIcon round={true} size={30} />
                </TelegramShareButton>

                <span
                  style={{
                    fontSize: 30,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${li.link_format}${
                        li.reference ? `?reference=${li.reference}` : ""
                      }`
                    )

                    toast("Share link copied", {
                      position: "bottom-center",
                      icon: "👏",
                      style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        height: "60px",
                        fontSize: "20px",
                        color: "#713200",
                      },
                    })
                  }}
                >
                  <iconify-icon icon="material-symbols:content-copy"></iconify-icon>
                </span>

                <Toaster />
              </td>

              <td>
                <p className="disable" onClick={() => link_disable(li._id)}>
                  Disable
                </p>
              </td>
            </tr>
          ))}

          {/* <tr>
            <td>#11100</td>
            <td>Car Rental</td>
            <td>&#8358;120,000:00</td>
            <td className="status">
              <span>Pending</span>
            </td>
            <td>10:00am</td>
            <td>14th Jan, 2022</td>
            <td>1111</td>
          </tr>

          <tr>
            <td>#11100</td>
            <td>Car Rental</td>
            <td>&#8358;120,000:00</td>
            <td className="status">
              <span>Pending</span>
            </td>
            <td>10:00am</td>
            <td>14th Jan, 2022</td>
            <td>1111</td>
          </tr> */}
        </tbody>
      </table>
    </main>
  )
}

export default List
