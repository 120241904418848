import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SignInForm = () => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [secured, setSecured] = useState("password")
  const [page, setPage] = useState("")
  const [otp, setOTP] = useState("")

  const navigate = useNavigate();



  const login = (ev) => {
    ev.preventDefault()
    setLoading(true)
    let data = {
      userName: email.toLowerCase().trim(),
      password
    }
    axios.post('/login', data)
      .then((res) => {
        if (res.data.message === "success") {
          localStorage.setItem("email", res.data.response.email)
          localStorage.setItem("id", res.data.id)
          // localStorage.setItem("token", res.data.token)
          setLoading(false)
          setPage('code')
          // navigate('/role-select')
        } else {
          alert(res.data.message)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        console.log({
          err
        })
      })
  }

  const confirm = (ev) => {
    ev.preventDefault()
    setLoading(true)
    let data = {
      code: otp.trim(),
      user_id: localStorage.getItem('id')
    }
    axios.post('/verify-login-code', data)
      .then((res) => {
        if (res.data.message === "success") {
          localStorage.setItem("token", res.data.token)
          setLoading(false)
          navigate('/role-select')
        } else {
          alert(res.data.details)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        console.log({
          err
        })
      })
  }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }


  return (
    page === "code" ?
      <main className="sign-up-form-container">
        <header>
          <h2>Welcome Back!</h2>
          <p>Please check you mail to get your OTP to complete login</p>
        </header>

        <form action="">
          <span>
            <label htmlFor="">OTP</label>
            <input type="text" placeholder="Enter OTP sent to your email" value={otp} onChange={(ev) => setOTP(ev.target.value)} />
          </span>
        </form>

        <div className="form-cta sign-in-form-cta">
          <button type="submit" onClick={(ev) => confirm(ev)}>Confirm</button>

          <p className='text-[#4DBCE2]'>Don't have an account? <Link className='ml-1 text-[#060D1C]' to='/sign-up'>Sign Up</Link></p>
        </div>
      </main>
      :
      <main className="sign-up-form-container">
        <header>
          <h2>Welcome Back!</h2>
          <p>Let’s get you back in.</p>
        </header>

        <form action="">
          <span>
            <label htmlFor="">Email</label>
            <input type="email" placeholder="enter your email or phone" value={email} onChange={(ev) => setEmail(ev.target.value)} />
          </span>

          <span>
            <label htmlFor="">Password</label>
            <input type={secured} placeholder="input password" value={password} onChange={(ev) => setPassword(ev.target.value)} />
          </span>

          <div className='flex flex-row items-center'>
            <input style={{ width: 20 }} name='check' type='checkbox' checked={secured !== "password" ? true : false} onChange={() => setSecured(secured === "password" ? "text" : "password")} />
            <label className='mt-4 ml-3' htmlFor="check" onClick={() => setSecured(secured === "password" ? "text" : "password")}>Show Password</label>
          </div>

          <p className="forgot-password" onClick={() => navigate('/forgot-password')}>Forgot Password?</p>
        </form>

        <div className="form-cta sign-in-form-cta">
          <button type="submit" onClick={(ev) => login(ev)}>Login</button>

          <p className='text-[#4DBCE2]'>Don't have an account? <Link className='ml-1 text-[#060D1C]' to='/sign-up'>Sign Up</Link></p>
        </div>
      </main>
  );
};

export default SignInForm;
