import React from "react"

import Navbar from "../../components/Navbar/Navbar"
import "./heroe-container.css"
import CardIllustration from "../../Images/cards.png"
import HeaderImage1 from "../../Images/headerImg4.jpg"
import HeaderImage2 from "../../Images/headerImg2.jpg"
import HeaderImage3 from "../../Images/headerImage3.jpg"
import { Link, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"



function HeroeContainer() {
  const navigate = useNavigate()

  return (
    <div className="heroe-container">
      <Navbar />
      <header className="heroe-content">
        <div>
          <motion.h1 animate={{ x: 0, opacity: 1 }} initial={{x: 50, opacity: 0.4}} transition={{ ease: "easeInOut", duration: 0.8 }} className="text-left text-white text-xl">
            {"Payment Solution For\n Merchants and Businesses"}
          </motion.h1>
          
          <motion.p  animate={{ x: 0, opacity: 1 }} initial={{x: 50, opacity: 0.4}} transition={{ ease: "easeInOut", duration: 0.8 }} className="text-left text-gray-400 w-[80%]">
           {" Kudipal offers a payment solution for merchants and businesses to \naccept payments dynamically."}
          </motion.p>
          <div className="header-cta">
            {/* <button>
            <iconify-icon class='iconify' icon="ci:download"></iconify-icon>
            <p>Download App</p>
          </button> */}
            <motion.button whileHover={{scale:0.9}} onClick={() => navigate("/sign-up")}>Sign up</motion.button>
            <motion.button whileHover={{scale:0.9}} onClick={() => navigate("/sign-in")}>Sign in</motion.button>
          </div>
        </div>

        <img className=" ml-20 mb-20 w-[500px] h-fit" src={CardIllustration} alt="" />
      </header>

      <footer>
        <Link to="/sign-up" className="feature">
          <img src={HeaderImage1} alt="" />
          <span>
            <article>
              <h5>Available For Artisians</h5>
              <p>
                Kudipal allows Artisans, self-employed masses, and small-scale
                businesses to accept payments.
              </p>
            </article>
            <button>
              <iconify-icon
                className="iconify"
                icon="bx:right-arrow-alt"
              ></iconify-icon>
            </button>
          </span>
        </Link>

        <Link to="/sign-up" className="feature">
          <img src={HeaderImage2} alt="" />
          <span>
            <article>
              <h5>Secure Wallets</h5>
              <p>
                Kudipal provides a secure wallet for users to securely receive
                funds.
              </p>
            </article>
            <button>
              <iconify-icon
                className="iconify"
                icon="bx:right-arrow-alt"
              ></iconify-icon>
            </button>
          </span>
        </Link>

        <Link to="/sign-up" className="feature">
          <img src={HeaderImage3} alt="" />
          <span>
            <article>
              <h5>Lightnen fast transaction</h5>
              <p>Kudipal offers fast transactions without compromising costs</p>
            </article>
            <button>
              <iconify-icon
                className="iconify"
                icon="bx:right-arrow-alt"
              ></iconify-icon>
            </button>
          </span>
        </Link>
      </footer>
    </div>
  )
}

export default HeroeContainer
