import React from 'react';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSide from './ForgotPasswordSide';


const ForgotPasswordContainer = () => {

  return (
    <section className="sign-up-page">
      <ForgotPassword />
      <ForgotPasswordSide />

    </section>
  );
};

export default ForgotPasswordContainer;
