import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './dashboard-transaction.css';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import FundModal from '../Modals/FundModal';

const TransactionBalance = ({ inflow, outflow, user, bank }) => {

  const [fund_modal, setFundModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {fund_modal ? (
        <FundModal setModal={setFundModal} bank={bank} />
      ) : (
        <>
          <div className="transaction-balance">
            <header className="">
              <h3>Transaction</h3>

              <span className="transaction-cta">
                {['Owner'].includes(localStorage.getItem('role')) && <button className="widthrawal-btn" onClick={() => navigate('/dashboard/withdrawal')}>Withdraw</button>}
                <button className="fund-btn" onClick={() => setFundModal(true)}>Fund Wallet</button>
              </span>
            </header>

            <main className='flex flex-col md:flex-row gap-y-4 md:gap-x-0'>
              <div className="total-available-balance">
                <header>
                  <p>Total Inflow</p>

                  {/* <span>
              <Icon className='trend-up-icon' icon="icon-park-solid:trend" />
              +20%
            </span> */}
                </header>

                <h3>NGN{Number(inflow).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
              </div>

              <div className="total-available-balance">
                <header>
                  <p>Total Outflow</p>

                  {/* <span>
              <Icon icon="icon-park-solid:trend" />
              +20%
            </span> */}
                </header>

                <h3>NGN{Number(outflow).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
              </div>

              {['Owner'].includes(localStorage.getItem('role')) && <div className="create-new-link-div" onClick={() => navigate('/dashboard/create-transaction-link')}>
                <h4>Create A New transaction Link</h4>

                <Link to="/dashboard/create-transaction-link">
                  <span>
                    proceed
                    <Icon icon="mdi:arrow-top-right" />
                  </span>
                </Link>
              </div>}
            </main>
          </div>
        </>)}
    </>
  );
};

export default TransactionBalance;
