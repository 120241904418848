import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import VerifySuccess from '../../../../images/verify.svg';
import './fundmodal.css';


const FundModal = ({ setModal, bank }) => {
    const [num_copy, setNum] = useState(false)
    const [bank_copy, setB] = useState(false)
    const [name_copy, setName] = useState(false)
    return (
        <section className="apartment-added-modal-container">
            <div className="apartment-added-modal">
                {/* <img src={VerifySuccess} alt="" /> */}

                <h4>Fund Your Kudipal Wallet</h4>
                <div className='bank-div'>
                    <div className='bank-details' onClick={() => {
                        navigator.clipboard.writeText(bank.account_number)
                        setNum(true)
                        setName(false)
                        setB(false)

                    }} >
                        <h6>Account Number: </h6>
                        <p>{bank.account_number}</p>
                        <iconify-icon icon="fluent:copy-24-filled"></iconify-icon>
                        {num_copy && <p className='copied'>-Copied</p>}
                    </div>

                    <div className='bank-details' onClick={() => {
                        navigator.clipboard.writeText(bank.account_name)
                        setName(true)
                        setB(false)
                        setNum(false)

                    }} >
                        <h6>Account Name: </h6>
                        <p>{bank.account_name} flw</p>
                        <iconify-icon icon="fluent:copy-24-filled"></iconify-icon>
                        {name_copy && <p className='copied'>-Copied</p>}
                    </div>

                    <div className='bank-details' onClick={() => {
                        navigator.clipboard.writeText(bank.bank_name)
                        setB(true)
                        setName(false)
                        setNum(false)

                    }} >
                        <h6>Bank: </h6>
                        <p>{bank.bank_name}</p>
                        <iconify-icon icon="fluent:copy-24-filled"></iconify-icon>
                        {bank_copy && <p className='copied'>-Copied</p>}
                    </div>


                </div>

                <span className="apartment-added-modal-cta">
                    <div onClick={() => {
                        navigator.clipboard.writeText(`Here is my Kudipal wallet: \n Account Number: ${bank.account_number} \n Account Name: ${bank.account_name} flw \n Bank: ${bank.bank_name}`)
                        setName(true)
                        setB(true)
                        setNum(true)

                    }} className="modal-cta">
                        <span>Copy All</span>
                    </div>
                    <div onClick={() => setModal(false)} className="modal-cta">
                        <>Close</>
                    </div>
                </span>
            </div>
        </section>
    );
};

export default FundModal;
