import React, { useState } from 'react';
import './dashboard-settings.css';
import banks from '../../../components/banks';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SettingsBankInfo = ({ banking }) => {

  const [bank_name, setBankName] = useState(banking ? banking.bank_name : '');
  const [bank_code, setBankCode] = useState(banking ? banking.bank_code : '');
  const [account_number, setAccNum] = useState(
    banking ? banking.account_number : ''
  );
  const [account_name, setAccName] = useState(
    banking ? banking.account_name : ''
  );

  const [bb, setBB] = useState(banking ? banks.find(b => b.Code === banking.bank_code) : {})

  const [loading, setLoading] = useState(false);

  const bank = (ev) => {
    let bank_data = JSON.parse(ev);
    setBB(bank_data)
    setBankName(bank_data.Name);
    setBankCode(bank_data.Code);

    if (account_number.length === 10) {
      setLoading(true);

      axios
        .post('/get-account-name', {
          account_number,
          id: localStorage.getItem("id"),
          bank_code: bank_data.Code,
        })
        .then((acc) => {
          setLoading(false);
          console.log(acc.data);
          setAccName(acc.data.account_name);
        })
        .catch((err) => {
          setLoading(false);
          alert(err);
        });
    }

    console.log(bank_data.Name);
  };

  const acc_num = (v) => {
    setAccNum(v);

    if (v.length === 10 && bank_name !== '') {
      setLoading(true);
      axios
        .post('/get-account-name', {
          account_number: v,
          id: localStorage.getItem("id"),
          bank_code,
        })
        .then((acc) => {
          setLoading(false);
          console.log(acc.data);
          setAccName(acc.data.account_name);
        })
        .catch((err) => {
          setLoading(false);
          alert(err);
        });
    }
  };

  const submit = (ev) => {
    ev.preventDefault();
    setLoading(true);

    let data = {
      bank: {
        bank_code,
        bank_name,
        account_number,
        account_name,
        id: localStorage.getItem("id"),
        user_id: localStorage.getItem('id'),
      },
      user_id: localStorage.getItem('id'),
    };

    if (account_name === '' || account_number.length < 10) {
      setLoading(false);
      alert('Please provide correct details');
      return false;
    }

    axios
      .post('/update-user', data)
      .then((res) => {
        if (res.data.message === 'success') {
          setLoading(false);
          alert('Success');
        } else {
          setLoading(false);
          alert(res.data.details);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
        return false;
      });
  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }


  return (
    <main className="settings">
      <header>
        <h4>Bank Information</h4>
        <p>Set your bank account details for withdrawal</p>
      </header>

      <form action="">
        <span>
          <label htmlFor="">Bank Account Number</label>
          <input type="number" value={account_number}
            onChange={(ev) => acc_num(ev.target.value)} placeholder='00000000000' />
        </span>
        <span>
          <label htmlFor="">Choose Bank</label>
          <select value={JSON.stringify(bb)} onChange={(ev) => bank(ev.target.value)}>
            <option>Choose Bank</option>
            {banks.sort((a, b) => {
              if (a.Name > b.Name) return 1
              if (b.Name > a.Name) return -1
              return 0
            }).map((ban) => (
              <option value={JSON.stringify(ban)}>{ban.Name}</option>
            ))}
          </select>
        </span>

        <span>
          <label htmlFor="">Account Name</label>
          <input type="text" placeholder="Auto fill" value={account_name} />
        </span>

        <button className="save-changes-btn save-btn" onClick={(ev) => submit(ev)}>Save Changes</button>

      </form>
    </main>
  );
};

export default SettingsBankInfo;
