import React, { useEffect, useState } from "react"
// import Navbar from "../../components/Navbar/Navbar"
import { useNavigate } from "react-router-dom"
import LoggedInNavbar from "../../components/Navbar/LoggedInNavbar"
import ClipLoader from "react-spinners/ClipLoader"
import axios from "../../components/axios"

export function RoleSelect() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [teams, setTeams] = useState([])

  // const userArray = [
  //   { name: "John Alalade", role: "Developer", initials: "JA" },
  //   { name: "Kudipal", role: "Admin", initials: "K" },
  // ]

  useEffect(() => {
    setLoading(true)

    let id = localStorage.getItem('id')

    axios.post('/get-my-teams', {
      id
    })
      .then(res => {
        setLoading(false)
        setTeams(res.data.teams)
      })
      .catch(err => {
        console.log(err)
        alert(err.message)
      })

  }, [])

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }
  return (
    <main className="p-10 flex flex-1 h-screen flex-col items-center bg-gray-100">
      <LoggedInNavbar />
      <p className="mt-[10%] mb-2 text-primary text-3xl font-black">Select Team</p>

      <div className=" w-full items-center self-center mt-5 flex flex-col overflow-scroll">
        {teams.map((user) => {
          return (
            <button
              className=" bg-white hover:bg-tertiary transition flex flex-row items-center gap-x-6 text-start p-7 w-full md:w-2/5 h-22 mt-5 rounded-2xl font-bold"
              onClick={() => {
                localStorage.setItem("id", user.id)
                localStorage.setItem("role", user.role)
                navigate("/dashboard")
              }}
            >
              <div className="bg-primary w-14 h-14 rounded-full flex items-center justify-center text-center">
                <p className="text-white">{user.initials}</p>
              </div>

              <div>
                <p>{user.name}</p>
                <p className="text-gray-400">{user.role}</p>
              </div>
            </button>
          )
        })}
      </div>
    </main>
  )
}
