import React from 'react'

const SetNewPasswordSide = () => {
  return (
    <div className="sign-up-side-container-2">
      {/* <h1></h1> */}
    </div>
  )
}

export default SetNewPasswordSide