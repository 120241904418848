import React from 'react'
import './services.css'
function Services() {
    return (
        <section className='services-container'>

            <header>
                <h3>
                    Our Core Services At <span>Kudipal</span>
                </h3>
            </header>
            <main className='services'>
                <div>
                    <h4>Payment</h4>
                    <p>
                        Receive payments through different methods including Cards, USSD, Bank transfer, QR Codes
                    </p>
                </div>

                <div>
                    <h4>Security</h4>
                    <p>
                        Our transactions are seamless and fast
                    </p>
                </div>

                <div>
                    <h4>APIs</h4>
                    <p>
                        We provide API documentaions for businesses that are based on software and receive payments online and offline.
                    </p>
                </div>

                {/* <div>
                <h4>Pension</h4>
                <p>
                Lorem ipsum dolor sit amet, consectetur iscing elit. Quis ut eu bibendum tortor libero ac etiam. Lobortis semper a pulvinar donec porttitor velit turpis facilisis amet. Sed consequat.
                </p>
            </div>
            <div>
                <h4>Pension</h4>
                <p>
                Lorem ipsum dolor sit amet, consectetur iscing elit. Quis ut eu bibendum tortor libero ac etiam. Lobortis semper a pulvinar donec porttitor velit turpis facilisis amet. Sed consequat. 
                </p>
            </div>
            <div>
                <h4>Pension</h4>
                <p>
                Lorem ipsum dolor sit amet, consectetur iscing elit. Quis ut eu bibendum tortor libero ac etiam. Lobortis semper a pulvinar donec porttitor velit turpis facilisis amet. Sed consequat.
                </p>
            </div> */}
            </main>
        </section>
    )
}

export default Services;
