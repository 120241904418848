import React, { useState } from "react"
import "./dashboard-settings.css"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"
// import { Dropdown, Selection } from "react-dropdown-now"
import "react-dropdown-now/style.css"

const SettingsPaymentOptions = ({ user }) => {
  // const [notifyMe, setNotifyMe] = useState(user.notify_me)
  // const [notifyCustomer, setNotifyCustomer] = useState(user.notify_customer)
  const [paymentMethod, setPaymentMethod] = useState(0)
  const [loading, setLoading] = useState(false)

  const [cardDropdownVisible, setCardDropdownVisible] = useState(false)

  // const options = ["one", "two", "three"]

  // const defaultOption = options[0]

  const update_personal = (ev) => {
    setLoading(true)

    ev.preventDefault()

    let data = {
      // notify_customer: notifyCustomer,
      // notify_me: notifyMe,
      user_id: localStorage.getItem("id"),
      id: localStorage.getItem("id"),
    }

    axios
      .post("/update-user", data)
      .then((res) => {
        if (res.data.message === "success") {
          setLoading(false)
          alert("Success")
        } else {
          setLoading(false)
          alert(res.data.details)
        }
      })
      .catch((err) => {
        setLoading(false)
        alert(err)
        return false
      })
  }

  const DropDown = () => {
    return(
      <div className="flex-col absolute w-72 ml-[30%] mt-5">
        <div class="bg-white h-10 rounded-lg">
            <span class="text-lg font-bold mt-1 ml-4" onClick={() => {setCardDropdownVisible(true)}}>Select your option</span>
            <i class="bx bx-chevron-down"></i>
        </div>

        {cardDropdownVisible === false ? (null) : (<ul class="bg-white mt-2">
            <li class="option hover:bg-slate-100">
            
                <span class="text-base">Github</span>
            </li>
            <li class="option">
                <i class="bx bxl-instagram-alt"></i>
                <span class="option-text">Instagram</span>
            </li>
            <li class="option">
                <i class="bx bxl-linkedin-square"></i>
                <span class="option-text">Linkedin</span>
            </li>
            <li class="option">
                <i class="bx bxl-facebook-circle"></i>
                <span class="option-text">Facebook</span>
            </li>
            <li class="option">
                <i class="bx bxl-twitter"></i>
                <span class="option-text">Twitter</span>
            </li>
        </ul>)}
        
    </div>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className="settings">
      <header>
        <h4>Payment Options</h4>
        <p>Choose your payment option</p>
      </header>

      <form action="" className="formy">
        <div className="options">
          {paymentMethod === 0 ? (
            <span className="flex flex-row mt-5">
              <input
                type="checkbox"
                name="options"
                value="ussd"
                onChange={(ev) => setPaymentMethod(0)}
                checked={true}
              />
              <p className="absolute ml-12 mt-3.5">Card</p>
            </span>
          ) : (
            <span className="flex flex-row mt-5">
              <input
                type="checkbox"
                name="options"
                value="Card"
                onChange={(ev) => setPaymentMethod(0)}
                checked={false}
              />
              <p className="absolute ml-12 mt-3.5">Card</p>
            </span>
          )}

          {paymentMethod === 1 ? (
            <span className="flex flex-row mt-5">
              <input
                type="checkbox"
                name="options"
                value="ussd"
                onChange={(ev) => setPaymentMethod(1)}
                checked={true}
              />
              <p className="absolute ml-12 mt-3.5">Bank Transfer</p>
            </span>
          ) : (
            <span className="flex flex-row mt-5">
              <input
                className=""
                type="checkbox"
                name="options"
                value="Bank Transfer"
                onChange={(ev) => setPaymentMethod(1)}
                checked={false}
              />
              <p className="absolute ml-12 mt-3.5">Bank Transfer</p>
            </span>
          )}

          {paymentMethod === 2 ? (
            <div>
              <span className="flex flex-row mt-5">
                <input
                  type="checkbox"
                  name="options"
                  value="ussd"
                  onChange={(ev) => setPaymentMethod(2)}
                  checked={true}
                />
                <p className="absolute ml-12 mt-3.5">USSD</p>
              </span>
             <DropDown/>
              ;
            </div>
          ) : (
            <span className="flex flex-row mt-5">
              <input
                type="checkbox"
                name="options"
                value="ussd"
                onChange={(ev) => setPaymentMethod(2)}
                checked={false}
              />
              <p className="absolute ml-12 mt-3.5">USSD</p>
            </span>
          )}

          {paymentMethod === 3 ? (
            <span className="flex flex-row mt-5">
              <input
                type="checkbox"
                name="options"
                value="ussd"
                onChange={(ev) => setPaymentMethod(3)}
                checked={true}
              />
              <p className="absolute ml-12 mt-3.5">Mobile Money</p>
            </span>
          ) : (
            <span className="flex flex-row mt-5">
              <input
                type="checkbox"
                name="options"
                value="ussd"
                onChange={(ev) => setPaymentMethod(3)}
                checked={false}
              />
              <p className="absolute ml-12 mt-3.5">Mobile Money</p>
            </span>
          )}
        </div>

        <button className="save-btn" onClick={(ev) => update_personal(ev)}>
          Save Changes
        </button>
      </form>
    </main>
  )
}

export default SettingsPaymentOptions
