import React, { useEffect, useState } from 'react';
import './dashboard-settings.css';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SettingsPersonalInfo = ({ user }) => {

  const [first_name, setFName] = useState(user.first_name);
  const [last_name, setLName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [loading, setLoading] = useState(false);
  const [s, setS] = useState("")

  useEffect(() => {
    setS(s)
  },[s])

  const update_personal = (ev) => {
    setLoading(true)

    ev.preventDefault()

    let data = {
      first_name,
      last_name,
      email,
      phone,
      id: localStorage.getItem("id"),
      user_id: localStorage.getItem("id")
    }

    if (first_name === "" || last_name === "" || email === "" || phone === "") {
      alert("Please fill all fields")
      return
    }

    axios.post('/update-user', data)
      .then(res => {
        if (res.data.message === "success") {
          setLoading(false)
          alert("Success")
        } else {
          setLoading(false)
          alert(res.data.details)
        }
      })
      .catch(err => {
        setLoading(false)
        alert(err)
        return false
      })

  }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className='settings'>
      <header>
        <h4>Personal Information</h4>
        <p>Set your account details below</p>
      </header>

      <form action="">
        <div>
          <span>
            <label htmlFor="">First Name</label>
            <input type="text" placeholder="Olakunbi" value={first_name} onChange={(ev) => setFName(ev.target.value)} />
          </span>

          <span>
            <label htmlFor="">Last Name</label>
            <input type="text" value={last_name} onChange={(ev) => setLName(ev.target.value)} placeholder="Olabode" />
          </span>
        </div>

        <span>
          <label htmlFor="" >Email</label>
          <input type="text" value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder="olakunbiolabode01@gmail.com" />
        </span>
        <span>
          <label htmlFor="">Phone Number</label>
          <input type="text" value={phone} onChange={(ev) => setPhone(ev.target.value)} placeholder="8000000000" />
        </span>

        <button className="save-changes-btn save-btn" onClick={(ev) => update_personal(ev)}>Save Changes</button>

      </form>
    </main>
  );
};

export default SettingsPersonalInfo;
