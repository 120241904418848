import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import VerifySuccess from '../../../../images/verify.svg';
import './fundmodal.css';


const WithdrawOTPModal = ({ setModal, complete }) => {
    const [otp, setOTP] = useState('')
    return (
        <section className="apartment-added-modal-container">
            <div className="apartment-added-modal">
                {/* <img src={VerifySuccess} alt="" /> */}

                <h4>Please enter OTP sent to your mail to complete withdrawal request.</h4>
                <div className='bank-div'>
                    <div className='bank-details'>
                        <input type="text" className='input-otp' placeholder="Enter OTP sent to your email" value={otp} onChange={(ev) => setOTP(ev.target.value)} />
                    </div>


                </div>

                <span className="apartment-added-modal-cta">

                    <div onClick={() => setModal(false)} className="modal-cta">
                        <>Close</>
                    </div>

                    <div onClick={() => complete(otp)} className="modal-cta">
                        <span>Complete Withdrawal</span>
                    </div>

                </span>
            </div>
        </section>
    );
};

export default WithdrawOTPModal;
