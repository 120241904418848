// import { useNavigate } from "react-router-dom"
import React, { useState } from "react"
import "./navbar.css"
import KudipalIconDark from "../../Images/kudipal_icon_dark.png"

const LoggedInNavbar = () => {
  const [openHamburger, setOpenHamburger] = useState(false)
  // const navigate = useNavigate()

  // const navigateToSignUp = () => {
  //   navigate('/sign-up');
  // };
  // const navigateToSignIn = () => {
  //   navigate('/sign-in');
  // };

  return (
    <nav className="bg-primary flex flex-row w-full justify-between fixed top-0 left-0 px-12 py-7 items-center z-50">
      <div className="logo">
        <img alt="" className="p-3 " src={KudipalIconDark} />
      </div>
      <ul className={`text-white ${openHamburger && "open-state"}`}>
        <div className="hidden">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://documenter.getpostman.com/view/19076796/2s8Z711Xb5"
          >
            Developer
          </a>
        </div>

        {/* <li>
          <a href="/faq">FAQ</a>
        </li> */}
        {/* <li>
          <a href="/about">About Us</a>
        </li>
        <li>
          <a href="/calculator">Calculator</a>
        </li> */}
      </ul>

  
      <div
        className={`hambuger ${openHamburger && "open-state"}`}
        onClick={() => setOpenHamburger(!openHamburger)}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </nav>
  )
}

export default LoggedInNavbar
