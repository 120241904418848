import React, { useState } from 'react';
import './balance-withdrawal.css';
import axios from '../../../components/axios';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import WithdrawOTPModal from '../Modals/WithdrawOTP';

const WithdrawalConfirmation = ({
  nextWithdrawalStep,
  previousWithdrawalStep,
}) => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const navigate = useNavigate();


  const continueWithdrawal = (e) => {
    // e.preventDefault();
    setLoading(true)


    axios.post('/send-withdraw-verification', {
      user_id: localStorage.getItem("id")
    })
      .then(res => {
        setLoading(false)
        if (res.data.message === "success") {
          setModal(true)
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })

    // nextWithdrawalStep();
  };

  const complete = (code) => {
    setLoading(true)
    let data = {
      reason: "Kudipal withdrawal",
      recipient: localStorage.getItem("recipient_code"),
      amount: parseInt(localStorage.getItem("amount")),
      id: localStorage.getItem("id"),
      bank_code: localStorage.getItem("bank_code"),
      account_number: localStorage.getItem("account_number"),
      code
    }

    axios.post('/transfer', data)
      .then(res => {
        setLoading(false)
        if (res.data.message === "success") {
          localStorage.setItem("transfer_code", res.data.transfer_code)
          // nextWithdrawalStep();
          navigate('/dashboard');
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const previousWIthdrawal = (e) => {
    e.preventDefault();
    previousWithdrawalStep();
  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }


  return (
    modal ? <WithdrawOTPModal setModal={setModal} complete={complete} /> :
      <>
        {/* <div className="steps-filters step-filters-withdrawal">
        <div className="active-step step">
          <p>Bank Account</p>
          <span>01</span>
        </div>
        <div className="step-connector active-connector"></div>
        <div className="active-step step">
          <p>Withdrawal Amount</p>
          <span>02</span>
        </div>

        <div class="step-connector"></div>
        <div className="active-step step">
          <p>Withdrawal Confiration</p>
          <span>03</span>
        </div>
      </div> */}
        <section className="choose-account-details-container">
          <header>
            <h4>Withdrawal Confirmation</h4>
            <p>
              Confirm your withdrawal with your password in order to make your
              request successfull
            </p>
          </header>

          <main className="account-det-options withdrawal-confirmation-container">
            {/* <span id="custom-withdrawal-input">
          <label className="password-lable" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            placeholder="enter your password to confirm your withdrawal"
          />
        </span> */}

            <main className="apartment-price-breakdown withdrawal-confirmation-det">

              <span className='conf'>
                <p className='conf-topic'>Amount: </p>
                <p>{Number(localStorage.getItem('amount')).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
              </span>

              {/* <span>
              <p>From</p>
              <p>Kudipal</p>
            </span> */}

              <span className='conf'>
                <p className='conf-topic'>To: </p>
                <p>{localStorage.getItem("account_name")}</p>
              </span>

              <span className='conf'>
                <p className='conf-topic'>Bank: </p>
                <p>{localStorage.getItem("bank")}</p>
              </span>
            </main>
          </main>

          <div className="withdrawal-cta">
            <button onClick={previousWIthdrawal}>Previous</button>
            <button onClick={continueWithdrawal}>Confirm</button>
          </div>
        </section>
      </>
  );
};

export default WithdrawalConfirmation;
