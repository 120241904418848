import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../../../components/Dashboard Navbar/DashboardNavbar'
import TransactionBalance from './TransactionBalance'
import TrasactionList from './TrasactionList'
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";
import { DropDownFilter } from '../../../components/filter';

const timeInterval = ["Day", "Week", "Month", "Year"];

const DashboardTransactionContainer = () => {

  const [transactions, setTransaction] = useState([])
  const [inflow, setInflow] = useState()
  const [outflow, setOutFlow] = useState()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [bank, setBank] = useState({})
  const [interval, setInterval] = useState([new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(24, 0, 0, 0))]);


  useEffect(() => {
    let id = localStorage.getItem("id")
    setLoading(true)

    axios.post('/get-user', {
      id: id
    })
      .then(data => {
        setUser(data.data.user)
      })
      .catch(err => {
        console.log(err)
      })

      axios
      .post("/get-wallet", {
        user_id: id,
        id,
      })
      .then((data) => {
        setBank(data.data.wallet)
      })
      .catch((err) => {
        console.log(err)
      })


    axios.post('/get-user-transactions', {
      id: localStorage.getItem("id"),
      user_id: id,
      filter: interval
    })
      .then(data => {
        setTransaction(data.data.transactions)
      })
      .catch(err => {
        console.log(err)
      })

    axios.post('/transaction-flow', {
      id: localStorage.getItem("id"),
      user_id: id,
      filter: interval
    })
      .then(data => {

        console.log(data.data)

        setInflow(data.data.inflow)
        setOutFlow(data.data.outflow)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })

  }, [interval])

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className='dashboard-overview-container'>

      <DashboardNavbar />
      <DropDownFilter timeInterval={timeInterval} interval={interval} setInterval={setInterval} />
      <TransactionBalance inflow={inflow} outflow={outflow} user={user} bank={bank}/>

      <TrasactionList transactions={transactions} user={user} />
    </section>
  )
}

export default DashboardTransactionContainer
