import Calendar from 'react-calendar';
import { useState } from 'react';
import 'react-calendar/dist/Calendar.css';

export const DropDownFilter = ({ timeInterval, interval, setInterval }) => {

  const [open, setOpen] = useState(false)

  const onChange = (e) => {
    setOpen(false)
    setInterval(e)
    console.log(e)
  }
  return (

    <div>
      {!open && <div className="w-40 h-12 p-3 z-50 bg-primary rounded focus:outline-primary absolute right-14 top-32 shadow-lg text-slate-50" onClick={() => { setOpen(true) }}>
        <p>Choose Date</p>
      </div>}
      {open && <Calendar className="p-3 rounded focus:outline-primary absolute right-14 top-36 shadow-lg z-50" onChange={onChange} value={interval} selectRange />}
    </div>

    // <select
    //   className="p-3 rounded focus:outline-primary absolute right-14 top-8 shadow-lg"
    //   onChange={(e) => setInterval(e.target.value)}
    //   value={interval}
    // >
    //   {timeInterval.map((item, key) => {
    //     return <option value={item}>{item}</option>;
    //   })}
    // </select>
  );
};

export const DropDownFilter2 = ({ timeInterval, interval, setInterval }) => {
  return (
    <select
      className="w-40 h-12 p-3 rounded focus:outline-primary absolute right-56 top-8 shadow-lg"
      onChange={(e) => setInterval(e.target.value)}
      value={interval}
    >
      {timeInterval.map((item, key) => {
        return <option value={item}>{item}</option>;
      })}
    </select>
  );
};
