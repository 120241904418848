import React, { useState } from 'react'
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SettingsPassword = () => {
  const [current_password, setCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirm] = useState('');
  const [loading, setLoading] = useState(false);

  const update = (ev) => {
    setLoading(true);

    ev.preventDefault();

    setLoading(true);

    let data = {
      current_password,
      password,
      id: localStorage.getItem("id"),
      user_id: localStorage.getItem('id'),
    };
    if (current_password === '' || password === '' || confirm_password === '') {
      setLoading(false);
      alert('Please fill all fields');
      return false;
    }
    if (password !== confirm_password) {
      setLoading(false);
      alert("Password deos'nt match");
      return false;
    }
    if (password.length < 8 || confirm_password.length < 8) {
      setLoading(false);
      alert('Password too short');
      return false;
    }

    axios
      .post('/update-password', data)
      .then((res) => {
        if (res.data.message === 'success') {
          setLoading(false);
          alert('Success');
        } else {
          setLoading(false);
          alert(res.data.details);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
        return false;
      });
  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className='settings'>

      <header>
        <h4>Password</h4>
        <p>Update your account password below</p>
      </header>

      <form action="">
        <span>
          <label htmlFor="" >Current Password</label>
          <input type="password" value={current_password}
            onChange={(ev) => setCurrent(ev.target.value)} placeholder='Enter your current password' />
        </span>
        <span>
          <label htmlFor="">New Password</label>
          <input type="password" value={password}
            onChange={(ev) => setPassword(ev.target.value)} placeholder='Enter a new password' />
        </span>
        <span>
          <label htmlFor="">Confirm Password</label>
          <input type="password" value={confirm_password}
            onChange={(ev) => setConfirm(ev.target.value)} placeholder='Enter a new password' />
        </span>

        <button className="save-changes-btn save-btn" onClick={(ev) => update(ev)}>Save Changes</button>

      </form>
    </main>
  )
}

export default SettingsPassword