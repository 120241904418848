import React from 'react';
import SetNewPassword from './SetNewPassword';
import SetNewPasswordSide from './SetNewPasswordSide';


const SetNewPasswordContainer = () => {
  return (
    <section className="sign-up-page">
      <SetNewPassword />
      <SetNewPasswordSide />
    </section>
  );
};

export default SetNewPasswordContainer;
