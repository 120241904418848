import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://test-kudipal-backend.herokuapp.com/merchant'
  baseURL: "https://kudipal.herokuapp.com/merchant"
});
// baseURL: 'https://betatin-backend.herokuapp.com/'

instance.interceptors.request.use(
  async (config) => {
    const authHeader = config.headers?.Authorization;
    if (!authHeader || authHeader === "") {
      const token = localStorage.getItem("token")
      const bearerToken = `Bearer ${token}`;
      // console.log("ttt: " + token)
      if (bearerToken) {
        config.headers["Authorization"] = bearerToken;
      }
    }

    return config;
  },
  async (error) => {
    alert("case?");
    await Promise.reject(error);
  }
);

export default instance;