import React from 'react';
import './forgot-password.css';
// import EmailIcon from '../../../images/email-modal-icon.svg';
import { useNavigate } from 'react-router-dom';



const EmailSentModal = ({ setPasswordResetOpen, email }) => {
  const handleClose = (e) => {
    e.preventDefault();
    setPasswordResetOpen(false);
  };

  const navigate = useNavigate()
  return (
    <section className="password-reset-modal">
      <main>
        <button className='closeBtn' onClick={handleClose}> X</button>

        {/* <img src={EmailIcon} alt="" /> */}

        <span>
          <h4>Password Recovery Link Sent</h4>
          <p>Almost there! We’ve sent an email to {email}. Follow the prompt to reset your password.</p>
        </span>

        <button className='loginBtn' onClick={(ev) => navigate('/sign-in')}>Proceed To Login</button>
      </main>
    </section>
  );
};

export default EmailSentModal;
