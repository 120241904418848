import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";
import industries from './industries';

const SignUpForm = () => {

  const [page, setPage] = useState("1")

  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  // const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const [phone, setPhone] = useState("")
  const [business_name, setBisName] = useState("");
  const [business_industry, setBisIndustry] = useState("Agriculture");
  const [business_type, setBisType] = useState("");
  const [bis_number, setBisNumber] = useState("");
  const [bis_documents, setBisDocs] = useState({});
  const [director, setDirector] = useState("");
  const [bvn, setBVN] = useState("");
  const [secured, setSecured] = useState("password")


  const [document_type, setType] = useState("National ID")
  const [document_number, setDocNumber] = useState("")

  const [address, setAddress] = useState("")
  // const [business_documents, setBusinessDocs] = useState([]);

  const [user_id, setID] = useState("");

  const navigate = useNavigate();
  // const navigateToSignIn = () => {
  //   navigate('/sign-in');
  // };

  const sign_up = (ev) => {

    ev.preventDefault()
    setLoading(true)

    let formData = new FormData()

    formData.append('first_name', first_name)
    formData.append('last_name', last_name)
    formData.append('email', email.toLowerCase().trim())
    // formData.append('phone', phone)
    formData.append('business_address', address)
    formData.append('password', password)

    formData.append('business_name', business_name)
    formData.append('business_industry', business_industry)
    formData.append('business_number', bis_number)
    formData.append('director', director)

    for (let i = 0; i < bis_documents.length; i++) {
      formData.append('files', bis_documents[i]);
    }


    if (first_name === "" || last_name === "" || email === "" || password === "") {
      alert("Please input all feild.")
      setLoading(false)
      return false
    }

    if (password !== confirm_password) {
      alert("Passwords does not match.")
      setLoading(false)
      return false
    }
    else {
      axios.post('/register', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {
          localStorage.setItem("email", res.data.user.email)
          localStorage.setItem("id", res.data.id)
          setID(res.data.id)
          localStorage.setItem("token", res.data.token)
          setLoading(false)
          setPage("2")
          // navigate('/dashboard')
          console.log(res.data)
        })
        .catch(err => {
          setLoading(false)
          console.log({
            err
          })
        })
    }
  }

  const verification = (ev) => {

    ev.preventDefault()
    setLoading(true)

    let formData = new FormData()

    formData.append('business_name', business_name)
    formData.append('business_industry', business_industry)
    formData.append('business_type', business_type)
    formData.append('business_number', bis_number)
    formData.append('business_address', address)
    formData.append('director', director)

    formData.append('director_phone', phone)
    formData.append('document_type', document_type)
    formData.append('document_number', document_number)
    formData.append('bvn', bvn)

    formData.append('user_id', user_id)

    formData.append('file', bis_documents);


    if (business_name === "" || bis_number === "" || document_number === "" || phone === "" || phone === "") {
      alert("Please input all feild.")
      setLoading(false)
      return false
    }
    else {
      axios.post('/verification', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {

          setLoading(false)
          navigate('/dashboard')
          console.log(res.data)
        })
        .catch(err => {
          setLoading(false)
          console.log({
            err
          })
        })
    }
  }

  // const nexter = (ev) => {
  //   ev.preventDefault()
  //   setPage("2")
  // }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className="sign-up-form-container">
      <header>
        <h2>Sign Up</h2>
        <p>Let’s get you started. Create an account to begin</p>
      </header>
      {page === '1' &&
        <>
          <form action="">
            <div className="form-full-name">
              <span>
                <label htmlFor="">FirstName</label>
                <input type="text" placeholder="Doe" value={first_name} onChange={(ev) => setFirstName(ev.target.value)} />
              </span>
              <span>
                <label htmlFor="">Last Name</label>
                <input type="text" placeholder="John" value={last_name} onChange={(ev) => setLastName(ev.target.value)} />
              </span>
            </div>

            <span>
              <label htmlFor="">Email</label>
              <input type="email" placeholder="enter your email address" value={email} onChange={(ev) => setEmail(ev.target.value)} />
            </span>

            {/* <span>
              <label htmlFor="">Phone</label>
              <input type="tel" placeholder="8000000000" value={phone} onChange={(ev) => setPhone(ev.target.value)} />
              
            </span> */}
            {/* <p>+234 &nbsp; |</p> */}

            <span>
              <label htmlFor="">Password</label>
              <input type={secured} placeholder="password" value={password} onChange={(ev) => setPassword(ev.target.value)} />
            </span>

            <span>
              <label htmlFor="">Confirm Password</label>
              <input type={secured} placeholder="password" value={confirm_password} onChange={(ev) => setConfirmPassword(ev.target.value)} />
            </span>

            <div className='flex flex-row items-center'>
              <input style={{ width: 20 }} name='check' type='checkbox' checked={secured !== "password" ? true : false} onChange={() => setSecured(secured === "password" ? "text" : "password")} />
              <label className='mt-4 ml-3' for="check" onClick={() => setSecured(secured === "password" ? "text" : "password")}>Show Password</label>
            </div>

          </form>

          <div className="form-cta">
            <button type="submit" onClick={(ev) => sign_up(ev)}>Submit</button>

            <p>Already have an account? <Link className='ml-1 text-[#4DBCE2]' to='/sign-in'>Login</Link></p>
          </div>
        </>}

      {page === '2' &&
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}>
            <span onClick={() => setPage("1")}>
              <iconify-icon icon="material-symbols:arrow-back-rounded" width="60" height="60" ></iconify-icon>
            </span>

            <span onClick={() => navigate('/dashboard')}>
              <p style={{
                fontWeight: "bolder",
                fontSize: 20
              }}>
                Skip
              </p>
              {/* <iconify-icon icon="material-symbols:arrow-forward-rounded" width="60" height="60" ></iconify-icon> */}
            </span>
          </div>

          <form action="">
            <div className="form-full-name">
              <span>
                <label htmlFor="">Business Name</label>
                <input type="text" placeholder="Kudipal Inc" value={business_name} onChange={(ev) => setBisName(ev.target.value)} />
              </span>

              <span>
                <label htmlFor="">Industry</label>
                <select value={business_industry} onChange={(ev) => setBisIndustry(ev.target.value)}>
                  {
                    industries.map(ids => <option value={ids}>{ids}</option>)
                  }
                </select>
              </span>

            </div>
            <div className="form-full-name">

              <span>
                <label htmlFor="">Businness Type</label>
                <select value={business_type} onChange={(ev) => setBisType(ev.target.value)}>

                  <option value={"Sole Proprietor"}>Sole Proprietor</option>
                  <option value={"Partnership"}>Partnership</option>
                  <option value={"Limited Liability Company"}>Limited Liability Company</option>
                  <option value={"Public Limited Company"}>Public Limited Company</option>
                  <option value={"Coporate Society"}>Coporate Society</option>
                  <option value={"Government Organizaton"}>Government Organizaton</option>
                </select>

              </span>

              <span>
                <label htmlFor="">RC Number</label>
                <input type="email" placeholder="000000" value={bis_number} onChange={(ev) => setBisNumber(ev.target.value)} />
              </span>


            </div>

            <div className="form-full-name">
              <span>
                <label htmlFor="">Address</label>
                <input type="text" placeholder="Businness Address" value={address} onChange={(ev) => setAddress(ev.target.value)} />
              </span>

              <span>
                <label htmlFor="">Upload CAC Certificate / Utility Bill</label>
                <input type="file" onChange={(ev) => setBisDocs(ev.target.files[0])} />
              </span>
            </div>

          </form>

          <div className="form-cta">
            <button type="submit" onClick={(ev) => {
              ev.preventDefault()
              setPage('3')
            }}>Next</button>

            <p>Already have an account? <Link to='/sign-in'>Login</Link></p>
          </div>
        </>}

      {page === '3' &&
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}>
            <span onClick={() => setPage("1")}>
              <iconify-icon icon="material-symbols:arrow-back-rounded" width="60" height="60" ></iconify-icon>
            </span>

            <span onClick={() => navigate('/dashboard')}>
              <p style={{
                fontWeight: "bolder",
                fontSize: 20
              }}>
                Skip
              </p>
              {/* <iconify-icon icon="material-symbols:arrow-forward-rounded" width="60" height="60" ></iconify-icon> */}
            </span>
          </div>

          <form action="">

            <div className="form-full-name">


              <span>
                <label htmlFor="">Director Name</label>
                <input type="name" placeholder="Director Name" value={director} onChange={(ev) => setDirector(ev.target.value)} />
              </span>

            </div>

            <div className="form-full-name">

              <span>
                <label htmlFor="">Director Phone</label>
                <input type="tel" placeholder="8000000000" value={phone} onChange={(ev) => setPhone(ev.target.value)} />
              </span>

              <span>
                <label htmlFor="">Director BVN</label>
                <input type="tel" placeholder="22020020202" value={bvn} onChange={(ev) => setBVN(ev.target.value)} />
              </span>

            </div>

            <div className="form-full-name">

              <span>
                <label htmlFor="">Director ID Type</label>

                <select value={document_type} onChange={(ev) => setType(ev.target.value)}>
                  <option value="National ID">National ID</option>
                  <option value="International Passport">International Passport</option>
                  <option value="Driver's License">Driver's License</option>
                </select>
              </span>

              <span>
                <label htmlFor="">Director ID Number</label>
                <input type="text" placeholder='77444020202' value={document_number} onChange={(ev) => setDocNumber(ev.target.value)} />
              </span>

            </div>

            {/* <div className="form-full-name">
              <span>
                <label htmlFor="">Address</label>
                <input type="text" placeholder="Businness Address" value={address} onChange={(ev) => setAddress(ev.target.value)} />
              </span>

              <span>
                <label htmlFor="">Upload CAC Certificate / Utility Bill</label>
                <input type="file" onChange={(ev) => setBisDocs(ev.target.files[0])} />
              </span>
            </div> */}

          </form>

          <div className="form-cta">
            <button type="submit" onClick={(ev) => verification(ev)}>Next</button>

            <p>Already have an account? <Link to='/sign-in'>Login</Link></p>
          </div>
        </>
      }
    </main>
  );
};

export default SignUpForm;
